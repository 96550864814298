import { ChangeEvent, useEffect, useState } from 'react'
import { getFieldError, TInputValidator } from '../formInput/FormInput.utils'
import { HiOutlineCheck } from 'react-icons/hi'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { Transition } from '@headlessui/react'

interface IFormCheckbox {
  name: string
  label?: string
  description?: string
  wasSubmitted?: boolean
  validators?: TInputValidator[]
  className?: string
  colour?: 'acai' | 'pitaya'
  showDescription?: boolean
  initialValue: boolean
  onValidationError?: ({ error }: { error: string }) => void
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const SPACEBAR_KEY_CODE = 32

export function FormCheckbox({
  name,
  label,
  description,
  validators = [],
  wasSubmitted = false,
  className,
  colour = 'acai',
  showDescription = true,
  initialValue = false,
  onValidationError = () => {},
  onChange = () => {},
}: IFormCheckbox) {
  const [value, setValue] = useState(initialValue)
  const [touched, setTouched] = useState(false)
  const errorMessage = getFieldError(value, validators)
  const displayErrorMessage = (wasSubmitted || touched) && errorMessage

  const handleChange = (event) => {
    setValue(!value)
    onChange(event)
  }

  const handleKeyPress = (event) => {
    if (event?.keyCode === SPACEBAR_KEY_CODE) {
      handleChange(event)
    }
  }

  useEffect(() => {
    if (!onValidationError || typeof onValidationError !== 'function') {
      return
    }

    onValidationError({
      error: errorMessage || null,
    })
  }, [errorMessage])

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const colourToCheckedCheckboxClass = {
    acai: 'before:border-acai before:bg-acai',
    pitaya: 'before:border-pitaya before:bg-pitaya',
  }

  const colourToFocusedCheckboxClass = {
    acai: 'before:border-acai-60',
    pitaya: 'before:border-pitaya-60',
  }

  return (
    <div
      className={`flex justify-start text-left relative transition-all mb-8 ${
        label ? 'flex-col' : 'flex-row'
      } ${className}`}
    >
      <input
        type="checkbox"
        id={name}
        name={name}
        defaultChecked={value}
        checked={value}
        value={value.toString()}
        className="absolute w-0 h-0 peer -z-1"
        onChange={handleChange}
        onBlur={() => setTouched(true)}
        onKeyPress={(e) => handleKeyPress(e)}
      />

      <label
        htmlFor={name}
        className={`
                    flex before:shrink-0 select-none cursor-pointer text-black text-xl leading-10 font-mori
                    before:content-[''] before:border-2 before:w-8 before:h-8 before:block before:rounded-md before:mr-3
                    peer-focus:${colourToFocusedCheckboxClass[colour]}
                    ${
                      value
                        ? colourToCheckedCheckboxClass[colour]
                        : 'before:border-black-20'
                    }
                    ${displayErrorMessage && 'before:border-error'}
                `}
      >
        <span className="leading-10">{label}</span>
        <HiOutlineCheck
          className={`text-core-white h-6 w-6 absolute top-1 left-1 ${
            value ? 'block' : 'hidden'
          }`}
        />
      </label>

      <Transition
        show={showDescription}
        enter="transition-all duration-300 ease-linear"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-24"
        leave="transition-all duration-300 ease-linear"
        leaveFrom="opacity-100 max-h-24"
        leaveTo="opacity-0 max-h-0"
      >
        <ContentfulMarkdown
          overrideClass={`text-lg select-none text-boulder font-mori text-pretty ${
            label ? 'ml-11 mt-2' : ''
          }`}
          overrideStyles={{
            a: ({ children, href }) => (
              <a
                className="pointer-events-auto text-pitaya"
                href={href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {` ${children} `}
              </a>
            ),
          }}
        >
          {description}
        </ContentfulMarkdown>
      </Transition>

      {displayErrorMessage && (
        <span
          role="alert"
          id={`${name}-error`}
          className="absolute left-0 pt-1 text-base text-left top-full text-error font-mori"
        >
          {errorMessage}
        </span>
      )}
    </div>
  )
}
